import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './Login.module.scss';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { ReactComponent as MAINLOGO } from '../../assets/main-logo.svg';
import Validation from '../../utils/Validation';
import User from '../../redux/actions/User';
import DashboardLoading from '../../containers/Loading/DashboardLoading';
import ErrorBox from '../../components/ErrorBox/ErrorBox';

const customStyle = {
  input: {
    fontWeight: 'bold'
  },
  inputContainer: {
    marginBottom: 40
  },
  loginBtn: {
    fontSize: 18,
    fontWeight: 'bold',
    boxShadow: `0 8px 12px 0 rgba(0, 0, 0, 0.08)`
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: '600',
    color: '#666666'
  },
  error: {
    marginBottom: 20
  },
  availBtn: {
    display: 'inline-flex',
    textDecoration: 'none'
  }
};
class Login extends PureComponent {
  state = {
    email: '',
    password: '',
    errors: {},
    rememberMe: false,
    error: undefined,
    loading: false
  };

  componentDidMount = () => {
    document.getElementById('favicon').href = 'signup-favicon.ico';
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  submitLogin = async () => {
    this.setState({ loading: true });
    const { email, password } = this.state;
    const data = {
      email,
      password
    };
    const newErrors = Validation.validateForm(data);
    if (newErrors) {
      this.setState({ errors: newErrors, loading: false });
      console.log('invalidForm: ', newErrors);
      return null;
    }
    try {
      const result = await User.signInWithEmailAndPassword(data.email, data.password);
      if (result) {
        this.setState({
          loading: false,
          errors: {}
        });
        // return <Redirect to="/" />;
      }
      return null;
    } catch (error) {
      console.log(error);
      this.setState({ errors: { login: error.message }, loading: false });
      return null;
    }
  };

  handleKeepMeLoggedIn = () => {
    const { keepMeLoggedIn } = this.state;

    this.setState({ keepMeLoggedIn: !keepMeLoggedIn });
  };

  onClickForgotPassword = () => {
    const { history } = this.props;
    history.push('/reset-password');
  };

  onClickCreateAccount = () => {
    const { history } = this.props;
    history.push('/register');
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.submitLogin();
    }
  };

  render() {
    const { email, password, keepMeLoggedIn, errors, loading } = this.state;
    const { auth, accounts } = this.props;
    const { search } = window.location;
    const parsed = new URLSearchParams(search);
    if (auth.isFetching || accounts.isFetching) {
      return <DashboardLoading />;
    }
    if (auth.user && accounts.data) {
      if (parsed && parsed.get('destination')) {
        return <Redirect to={parsed.get('destination')} />;
      }
      return <Redirect to="/dashboard" />;
    }
    return (
      <div className={styles.all}>
        <Helmet>
          <title>Login | Fraud Blocker</title>
        </Helmet>
        <div className={styles.loginBox}>
          <div className={styles.loginBoxInner}>
            <div className={styles.logo}>
              <MAINLOGO />
            </div>
            <h1 className={styles.welcomeText}>Welcome Back!</h1>
            <div className={styles.signUpBlock}>
              Don’t have an account yet?{' '}
              <Link to="/register" className={styles.signupLink}>
                Sign Up Now
              </Link>
            </div>
            <div className={styles.loginForm}>
              <Input
                type="email"
                value={email}
                name="email"
                label="Email"
                labelStyle={customStyle.inputLabel}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                containerStyle={customStyle.inputContainer}
                style={customStyle.input}
                error={errors.email || null}
              />
              <Input
                showEye
                type="password"
                value={password}
                name="password"
                label="Password"
                labelStyle={customStyle.inputLabel}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                containerStyle={customStyle.inputContainer}
                style={customStyle.input}
                error={errors.password || null}
              />
            </div>
            {errors.login && <ErrorBox errorStyle={customStyle.error} error={errors.login} />}
            {!errors.login && auth.isDeleted && (
              <ErrorBox
                errorStyle={customStyle.error}
                error="Unable to log you in, please contact support for assistance"
              />
            )}

            <div onClick={this.handleKeepMeLoggedIn} className={styles.keepLogin}>
              <div className={styles.keepMeLoggedInContainer}>
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  checked={keepMeLoggedIn}
                  onChange={this.handleKeepMeLoggedIn}
                  name="keepMeLoggedIn"
                />
              </div>
              Keep me logged in
            </div>
            <div className={styles.twoInputsContainer}>
              <Button
                title="Login To My Account"
                color="green"
                style={customStyle.loginBtn}
                onClick={this.submitLogin}
                loading={loading}
              />
            </div>
            <div className={styles.bottomContainer}>
              <p className="green" onClick={this.onClickForgotPassword}>
                Forgot Password?
              </p>
              {/* <p className="green" onClick={this.onClickCreateAccount}>
                Create an account
              </p> */}
            </div>
          </div>
        </div>
        <div className={styles.loginRight}>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.object,
  accounts: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  accounts: state.accounts
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
