import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import styles from './CurrentUserBox.module.scss';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import User from '../../redux/actions/User';
import Data from '../../api/Data';
import Utils from '../../utils/Utils';
import { ReactComponent as LogoutIcon } from '../../assets/exit-icon.svg';
// import { ReactComponent as PlanIcon } from '../../assets/starter-plan.svg';
import PlanIcon from '../../assets/starter-icon.png';
import CrownIcon from '../../assets/pro-icon.png';
// import { ReactComponent as CrownIcon } from '../../assets/crown.svg';
import { ReactComponent as TacoIcon } from '../../assets/taco.svg';
import CanceledPlanIcon from '../../assets/canceled_icon.svg';
import CROWN from '../../assets/crown-blue.svg';

class CurrentUserBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      clicks: 0,
      error: ''
    };
  }

  onClickUpgradePlan = () => {
    const { history } = this.props;
    history.push('/account/billing/subscription');
  };

  getPlanName = subscription => {
    if (subscription && subscription.plan) {
      if (subscription.appSumoSubscription) {
        return subscription.appSumoSubscription.plan.nickname;
      }
      return subscription.plan.nickname;
    }
    return 'Starter Plan';
  };

  logout = () => {
    User.signOut();
  };

  fetchSiteClicks = async () => {
    const { user, accounts } = this.props;
    try {
      const subscription = Utils.getSingleSubscription(accounts, accounts.data.id);
      if (!subscription) {
        this.setState({ clicks: 0 });
        return;
      }
      const result = await Data.getAllSitesClicks(user.account_id, subscription.id, user.timezone);
      if (result && result.length && !result.errno) {
        const total = result.reduce((acc, item) => {
          return acc + item.clicks;
        }, 0);
        this.setState({ clicks: total, error: '' });
      } else {
        this.setState({ clicks: 0 });
      }
    } catch (error) {
      console.log(error);
      this.setState({ error: error.message });
    }
  };

  componentDidMount = () => {
    this.fetchSiteClicks();
  };

  componentDidUpdate = preProps => {
    if (
      this.props.activeDomain &&
      this.props.activeDomain.data &&
      preProps.activeDomain &&
      preProps.activeDomain.data &&
      preProps.activeDomain.data.id !== this.props.activeDomain.data.id
    ) {
      this.fetchSiteClicks();
    }
    ReactTooltip.rebuild();
  };

  getUsage = (monthClicks, total) => {
    return (monthClicks * 100) / total;
  };

  render() {
    const { user, accounts } = this.props;
    const { clicks } = this.state;
    const subscription = Utils.getSingleSubscription(accounts, user.account_id);
    const planName = this.getPlanName(subscription);
    const total =
      subscription &&
        subscription.plan &&
        subscription.plan.metadata &&
        subscription.plan.metadata.clicks
        ? parseInt(subscription.plan.metadata.clicks, 10)
        : 0;
    return (
      <div className={styles.userBox}>
        <ReactTooltip id="logoutBtn">Sign out</ReactTooltip>
        <div className={styles.nameBox}>
          <div className={styles.nameAndEmail}>
            <p className={styles.userName}>{`${user.first_name || 'Your'} ${user.last_name ||
              'Name'}`}</p>
            <p className={styles.userEmail}>{user.email}</p>
          </div>
          <span data-tip data-for="logoutBtn" onClick={this.logout} className={styles.logout}>
            <LogoutIcon />
          </span>
        </div>
        {user && !['Viewer', 'Client'].includes(user.role) && (
          <div className={styles.planBox}>
            <div className={styles.planRow}>
              {subscription &&
                subscription.status !== 'canceled'
                ? <>
                  {planName.toLowerCase().includes('appsumo') ? (
                    <div className={styles.appsumoPlanIcon}>
                      <TacoIcon />
                    </div>
                  ) : planName.toLowerCase().includes('starter') ? (
                    <div className={styles.planIcon}>
                      <img src={PlanIcon} alt="starter" />
                    </div>
                  ) : (
                    <div className={styles.planIcon}>
                      <img src={CrownIcon} alt="pro" />
                    </div>
                  )}
                </> : <div className={styles.planIcon}>
                  <img src={CanceledPlanIcon} alt="canceled" />
                </div>}
              {subscription &&
                (subscription.status !== 'canceled' &&
                  planName ? (
                  <div className={styles.currentPlan}>
                    <div className={styles.current}>Currently on the</div>
                    <div className={styles.planName}>{planName}</div>
                  </div>
                ) : moment.unix(subscription.current_period_end).diff(moment(), 'days') >= 0 ? (
                  <div className={styles.currentPlan}>
                    <div className={styles.canceledPlan}>Canceled Plan</div>
                    <div className={styles.planActiveUntil}>Active until{' '}{subscription
                      ? moment
                        .unix(subscription.current_period_end)
                        .format('MMMM D, YYYY')
                      : ''}
                    </div>
                  </div>
                ) : (
                  <div className={styles.canceledPlan}>Canceled Plan</div>
                ))}
            </div>
            <ProgressBar
              color={clicks > total ? '#fc584e' : '#10cd24'}
              percentage={this.getUsage(clicks, total)}
            />
            <div className={styles.clicks}>
              <div className={styles.clicksSection}>
                <div className={`${styles.clicksCount} ${clicks > total ? styles.limit : ''}`}>
                  {clicks.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                </div>
                <div className={styles.clicksText}>ad clicks</div>
              </div>
              <div className={styles.divider} />
              <div className={styles.clicksSection}>
                <div className={`${styles.clicksCount} ${clicks > total ? styles.limit : ''}`}>
                  {total.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                </div>
                <div className={styles.clicksText}>monthly ad clicks</div>
              </div>
            </div>
            {!planName.toLowerCase().includes('appsumo') ? (
              clicks <= total ? (
                <a onClick={this.onClickUpgradePlan} className={styles.upgradeLink}>
                  <img src={CROWN} />
                  {planName.toLowerCase().includes('starter')
                    ? 'Upgrade Now To Pro'
                    : 'Upgrade Now'}
                </a>
              ) : (
                <a onClick={this.onClickUpgradePlan} className={styles.upgradeLinkRed}>
                  <span>!</span>Limit Reached. Upgrade Now!
                </a>
              )
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

CurrentUserBox.propTypes = {
  user: PropTypes.object,
  accounts: PropTypes.object,
  history: PropTypes.object,
  activeDomain: PropTypes.object
};

export default CurrentUserBox;
