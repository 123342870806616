import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './Account.module.scss';
import Button from '../../components/Button/Button';
import SuccessBox from '../../components/SuccessBox/SuccessBox';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import AddApiKeyModal from '../../containers/AddApiKeyModal/AddApiKeyModal';
import UpdateApiKeyModal from '../../containers/UpdateApiKeyModal/UpdateApiKeyModal';
import ApiKey from '../../api/ApiKey';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import CopyIcon from '../../assets/copy-key.png';
import EYE_SHOW from '../../assets/pass-show.svg';
import EYE_HIDE from '../../assets/pass-hide.svg';

const customStyles = {
    addDomainBtn: {
        width: 'auto',
        minWidth: 125,
        maxWidth: 125,
        marginRight: 15,
        border: 'none',
        fontWeight: 'normal',
        color: '#286cff'
    },
    removeBtn: {
        minWidth: 0,
        paddingLeft: 10,
        paddingRight: 10,
        cursor: 'pointer',
        fontSize: 12,
        height: 17,
        width: 'auto'
    },
    copied: {
        position: 'absolute',
        bottom: '25px',
        right: '10px',
        fontWeight: '600',
        padding: '10px 15px'
    },
    copyBtn: {
        marginLeft: '10px'
    }
};

class Api extends PureComponent {
    state = {
        totalUsage: 0,
        apiKeys: [],
        errors: {},
        loading: false,
        saveLoading: false,
        showSuccess: {},
        removeIndex: '',
        showUpdateLabelModal: false,
        showAddLabelModal: false,
        unmaskApiKey: null,
        copied: false
    };

    toggleApiKey = (val) => {
        this.setState({ unmaskApiKey: val });
    };

    toggleAddLabelModal = () => {
        this.setState({ showAddLabelModal: !this.state.showAddLabelModal });
    };

    toggleUpdateLabelModal = (apiKey = null) => {
        this.setState({ showUpdateLabelModal: !this.state.showUpdateLabelModal, selectedApiKey: apiKey });
    };

    componentDidMount = async () => {
        try {
            this.setState({ loading: true });

            const result = await ApiKey.getApiKeys();

            this.setState({ apiKeys: result, totalUsage: result.reduce((acc, curr) => { return acc + Number(curr.units_consumed) }, 0) });

            this.setState({ loading: false });
        } catch (error) {
            this.setState({ errors: { apiError: error.message }, loading: false });
        }
    };

    onClickRemoveApiKey = async (index) => {
        try {
            if (this.state.removeIndex) {
                return;
            }
            this.setState({ errors: { apiError: '' }, removeIndex: index });

            await ApiKey.removeApiKey(this.state.apiKeys[index].id);

            window.Intercom('trackEvent', 'remove-api-key', {
                label: this.state.apiKeys[index].label,
                key: this.state.apiKeys[index].key,
            });

            this.setState({ removeIndex: '', apiKeys: this.state.apiKeys.filter(apikey => apikey.key !== this.state.apiKeys[index].key) });
        } catch (error) {
            this.setState({ errors: { apiError: error.message }, removeIndex: '' });
        }
    };

    onNewKeyAdded = (apikey) => {
        this.setState({ apiKeys: [...this.state.apiKeys, apikey] });
    }

    onKeyUpdated = (apikey) => {
        const apiKeys = this.state.apiKeys.map(key => {
            if (key.id !== apikey.id) {
                return key;
            }
            return { ...key, ...apikey };
        });
        this.setState({ apiKeys });
    }

    copyApiKey = key => {
        navigator.clipboard.writeText(key);
        this.setState({ copied: key });
    };

    render() {
        const { apiKeys, showSuccess, errors, totalUsage, removeIndex, unmaskApiKey, selectedApiKey, showAddLabelModal, showUpdateLabelModal } = this.state;
        return (
            <div className={`${styles.content} ${styles.apiContent}`}>
                <h1 className={styles.title}>API</h1>
                <h3 className={styles.subTitle}>API Keys</h3>
                <p>
                    You&apos;ll need an API key to send requests view Fraud Blocker&apos;s API. <a className={styles.learnMoreApi} href="https://help.fraudblocker.com/en/articles/10263302-how-to-use-fraud-blocker-s-api" target="_blank" rel="noopener noreferrer">Learn more about our API.</a>
                </p>
                <div className={styles.subscriptionWrapper}>
                    <div className={styles.apiKeysList}>
                        <div className={styles.apiKeysListTable}>
                            <div className={`${styles.listHead} ${styles.apiKeysHead}`}>
                                <div className={styles.listHeading}>Label</div>
                                <div className={styles.listHeading}>API Keys</div>
                                <div className={styles.listHeading}>Created</div>
                                <div className={styles.listHeading}>Units Consumed</div>
                                <div className={styles.listHeading}>Limit</div>
                                <div className={styles.listHeading}>Action</div>
                            </div>
                            <div className={styles.listBody}>
                                {apiKeys.map((apikey, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`${styles.listBodyRow} ${removeIndex === index ? styles.removeSubRow : ''
                                                }`}
                                        >
                                            <div className={styles.apiLabel}>{apikey.label}</div>
                                            <div style={{ position: 'relative' }} className={styles.apiKey}>
                                                {unmaskApiKey === apikey.key ? <span><img onClick={() => this.toggleApiKey(null)} src={EYE_HIDE} alt="hide" />{apikey.key}</span> : <span><img src={EYE_SHOW} onClick={() => this.toggleApiKey(apikey.key)} alt="show" />******************{apikey.key.slice(-5)}</span>}<img height={22} width={21} style={customStyles.copyBtn} src={CopyIcon} onClick={() => this.copyApiKey(apikey.key)} alt="copy" />{this.state.copied === apikey.key && <SuccessBox override={true} style={customStyles.copied} message="Copied!" />}
                                            </div>
                                            <div className={styles.apiCreated}>
                                                {moment(apikey.created).format('MMMM DD, YYYY')}
                                            </div>
                                            <div className={styles.unitsConsumed}>
                                                {apikey.units_consumed.toLocaleString('en-US')}
                                            </div>
                                            <div className={styles.apiLimit}>
                                                No Limit
                                            </div>
                                            <div className={styles.apiActions}>
                                                <a
                                                    className={styles.setLimit}
                                                    href={null}
                                                    onClick={() => this.toggleUpdateLabelModal(apikey)}
                                                >
                                                    Edit
                                                </a>
                                                <DeleteIcon
                                                    style={customStyles.removeBtn}
                                                    onClick={() => this.onClickRemoveApiKey(index)}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={styles.apiListFooter}>
                            <div className={styles.usageLabel}>Total usage</div>
                            <div className={styles.usageValue}>
                                <strong>
                                    {totalUsage.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                                </strong>
                            </div>
                        </div>
                        <div className={styles.addDomain}>
                            <Button
                                title="+ Create API Key"
                                style={customStyles.addDomainBtn}
                                color="outline"
                                onClick={this.toggleAddLabelModal}
                            />
                        </div>
                        <div className={styles.messageSection}>
                            {errors.removeError && <ErrorBox error={errors.removeError} />}
                            {showSuccess.message && <SuccessBox message={showSuccess.message} />}
                        </div>
                        <div className={styles.zapIntegration}>
                            <h3 className={styles.subTitle}>Zapier API Integration</h3>
                            <p>
                                Fraud Blocker uses Zapier to automate our reporting data to your preferred dashboard.<br />
                                Zapier allows you to instantly connect to over 7,000 different apps - no coding needed - making it the most extensive automation platform in the world today.
                            </p>
                            <div className={styles.zapLinks}>
                                <a href="https://zapier.com/apps" target="_blank" rel="noopener noreferrer">View Zapier&apos;s apps</a>
                                <a href="https://zapier.com/developer/public-invite/215613/ff5c0614c0994f7ddf5d1dc0ff6f7db0/" target="_blank" rel="noopener noreferrer">Sign up with Zapier</a>
                                <a href="https://zapier.com/apps/fraud-blocker/integrations" target="_blank" rel="noopener noreferrer">Create a Zap</a>
                            </div>
                        </div>
                        {/* <div>
                            <h3 className={styles.subTitle}>Popular Zapier &quot;Zaps&quot; for Fraud Blocker</h3>
                        </div> */}
                    </div>
                </div>
                {showAddLabelModal && <AddApiKeyModal onClose={this.toggleAddLabelModal} onSuccess={this.onNewKeyAdded} />}
                {showUpdateLabelModal && selectedApiKey && <UpdateApiKeyModal onSuccess={this.onKeyUpdated} onClose={this.toggleUpdateLabelModal} id={selectedApiKey.id} label={selectedApiKey.label} />}
            </div>
        );
    }
}

Api.propTypes = {
    accounts: PropTypes.object,
    activeDomain: PropTypes.object,
    auth: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object
};

const mapStateToProps = state => ({
    accounts: state.accounts,
    activeDomain: state.activeDomain,
    auth: state.auth
});

export default connect(mapStateToProps)(Api);
